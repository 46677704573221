const API_URL = process.env.REACT_APP_API_BACKEND_URI;
const defaultsId = process.env.REACT_APP_CIDSS_DEFAULTS_ID;

export default {
  scenario: {
    getAll: () => `${API_URL}/scenarios`,
    new: () => `${API_URL}/scenarios`,
    getOne: scenarioId => `${API_URL}/scenarios/${scenarioId}`,
    copy: scenarioId => `${API_URL}/scenarios/${scenarioId}/copy`,
    featured: scenarioId => `${API_URL}/scenarios/featured/${scenarioId}`,
    updateRemove: scenarioId => `${API_URL}/scenarios/${scenarioId}`,
    getFeatured: () => `${API_URL}/scenarios/featured`,
    getOneSpatial: scenarioId => `${API_URL}/scenarios/${scenarioId}/spatial`,
    getOneResults: scenarioId => `${API_URL}/scenarios/${scenarioId}/results`,
    getOneResultsCatchments: scenarioId => `${API_URL}/scenarios/${scenarioId}/results/catchments`,
    getOneSpatialCatchmentPUs: (scenarioId, catchmentId) => `${API_URL}/scenarios/${scenarioId}/spatial/catchments/${catchmentId}/pus`,
    getOneResultsCatchmentPUs: (scenarioId, catchmentId) => `${API_URL}/scenarios/${scenarioId}/results/catchments/${catchmentId}/pus`,
    file: parentId => `${API_URL}/scenarios/${parentId}`,
    catchmentFile: (parentId, catchmentId) => `${API_URL}/scenarios/${parentId}/catchments/${catchmentId}`,
    run: scenarioId => `${API_URL}/scenarios/${scenarioId}/run`,
    stopRun: scenarioId => `${API_URL}/scenarios/${scenarioId}/stop`,
    reset: scenarioId => `${API_URL}/scenarios/${scenarioId}/reset`,
    rerun: scenarioId => `${API_URL}/scenarios/${scenarioId}/rerun`,
  },
  checkIP: () => `${API_URL}/ipcheck`,
  defaults: {
    get: () => `${API_URL}/defaults`,
    file: parentId => `${API_URL}/defaults/${parentId}`,
    catchmentFile: (parentId, catchmentId) => `${API_URL}/defaults/${parentId}/catchments/${catchmentId}`,
    newCatchment: () => `${API_URL}/defaults/catchments`,
    update: () => `${API_URL}/defaults/${defaultsId}`,
  },
  catchment: {
    updateRemoveScenario: (scenarioId, catchmentId) => `${API_URL}/scenarios/${scenarioId}/catchments/${catchmentId}`,
    updateRemoveDefault: (defaultId, catchmentId) => `${API_URL}/defaults/${defaultId}/catchments/${catchmentId}`,
  },
  users: {
    getAll: () => `${API_URL}/users`,
    new: () => `${API_URL}/users`,
    updateRemove: userId => `${API_URL}/users/${userId}`,
    reset: () => `${API_URL}/users/reset_password`,
  },
  attempts: {
    getOneSpatial: attemptId => `${API_URL}/attempts/${attemptId}/spatial`,
    getOneResults: attemptId => `${API_URL}/attempts/${attemptId}/results`,
    getOneResultsCatchments: attemptId => `${API_URL}/attempts/${attemptId}/results/catchments`,
    getOneSpatialCatchmentPUs: (scenarioId, catchmentId) => `${API_URL}/scenarios/${scenarioId}/spatial/catchments/${catchmentId}/pus`,
    getOneResultsCatchmentPUs: (attemptId, catchmentId) => `${API_URL}/attempts/${attemptId}/results/catchments/${catchmentId}/pus`,
    save: attemptId => `${API_URL}/attempts/${attemptId}/save`,
    saveNew: attemptId => `${API_URL}/attempts/${attemptId}/savenew`,
  }
};
