import React from 'react';
import ReactDOM from 'react-dom';

// polyfill shims
import flat from 'array.prototype.flat';

import App from 'Scenes/App';

// auth0
import { Auth0Provider } from 'react-auth0-spa';

// redux
import { Provider } from 'react-redux';
// import { store } from 'store';
import { wrapStore } from 'redux-in-worker';
import initialState from 'state';

// icons, build a library to use in all components of the app
import 'constants/icons';

// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
import Worker from 'workerize-loader!./store.worker';
import { unregister } from './registerServiceWorker';

// css
import 'bootstrap/dist/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import './index.scss';

const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;
const AUTH0_CALLBACK_URL = process.env.REACT_APP_AUTH0_CALLBACK_URI;

const instance = new Worker();
const store = wrapStore(instance, initialState);

ReactDOM.render(
  <Auth0Provider
    domain={AUTH0_DOMAIN}
    client_id={AUTH0_CLIENT_ID}
    redirect_uri={AUTH0_CALLBACK_URL}
  >
    <Provider store={store}>
      <App />
    </Provider>
  </Auth0Provider>,
  document.getElementById('root'),
);
unregister();

// polyfill code for outdated browsers
flat.shim();
