async function f(uri, method = 'GET', payload = null) {
  const opts = {
    method,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('id_token')}`,
    },
  }
  if (method === 'POST' || method === 'PUT') {
    opts.body = JSON.stringify(payload);
  }

  return await fetch(uri, opts);
}

export {
  f,
}