/* eslint-disable no-bitwise */
export const permissions = {
  FeaturedRead: 0x00000001,
  ScenarioManagerRead: 0x00000002,
  ScenarioManagerWrite: 0x00000004,
  ScenarioEditRead: 0x00000008,
  ScenarioEditWrite: 0x00000010,
  ScenarioViewRead: 0x00000020,
  ScenarioViewWrite: 0x00000040,
  DefaultsRead: 0x00000080,
  DefaultsWrite: 0x00000100,
  UserManagementRead: 0x00000200,
  UserManagementWrite: 0x00000400,
  Administrator: 0x00000800,
};

// if you'd like to calculate a role's permission number
// take the permissions a role would compromise and follow
// how it's done below, bitware OR "|", effectively
// combining the numbers together

// you can split them out by taking the combined permission
// number and using bitwise AND "&" with the resulting value
// equaling the permission your checking:
// (total & individual_permission) === individual_permission
// = true or false

const roles = {
  user: permissions.FeaturedRead | permissions.UserManagementRead,
  operator: permissions.FeaturedRead
    | permissions.ScenarioManagerRead
    | permissions.ScenarioEditRead
    | permissions.ScenarioViewRead
    | permissions.DefaultsRead
    | permissions.UserManagementRead,
  admin: permissions.Administrator,
};
export default roles;

export function HasPermissions(currentPerms, permToCheck) {
  // check if the user's current permissions includes administrator
  // we'll just exit here if they do
  if ((currentPerms & permissions.Administrator) === permissions.Administrator) {
    return true;
  }

  if ((currentPerms & permToCheck) === permToCheck) {
    return true;
  }

  return false;
}

export const userPermissionAccessor = user => {
  const metadata = user['https://truii.com/app_metadata'];
  const { role } = metadata.cidss;
  return roles[role];
};

export const userRoleAccessor = user => {
  const metadata = user['https://truii.com/app_metadata'];
  const { role } = metadata.cidss;
  return role;
};
