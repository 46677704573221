import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowLeft,
  faChartBar,
  faLayerGroup,
  faEdit as faREdit,
  faUndo,
  faDownload,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faSquare,
  faBars,
  faSpinner,
  faTable,
} from '@fortawesome/pro-light-svg-icons';
import {
  faCaretDown,
  faExclamationTriangle,
  faSpinner as faSolidSpinner,
  faCaretRight,
  faEquals,
  faTimes,
  faExclamationCircle,
  faFileUpload,
  faPlus,
  faCircle,
  faCheck,
  faEllipsisV,
  faTrash,
  faKey,
  faCogs,
  faCog,
  faEdit,
  faSave,
  faMinusOctagon,
} from '@fortawesome/pro-solid-svg-icons';
import { faExchange } from '@fortawesome/pro-duotone-svg-icons';

library.add(
  faArrowLeft,
  faSquare,
  faBars,
  faSpinner,
  faLayerGroup,
  faChartBar,
  faTable,
  faCaretDown,
  faREdit,
  faExclamationTriangle,
  faUndo,
  faCaretRight,
  faEquals,
  faTimes,
  faSolidSpinner,
  faDownload,
  faExclamationCircle,
  faFileUpload,
  faPlus,
  faCircle,
  faCheck,
  faEllipsisV,
  faTrash,
  faKey,
  faCogs,
  faCog,
  faEdit,
  faExchange,
  faSave,
  faMinusOctagon,
);
