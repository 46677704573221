import React, { useEffect, useState } from 'react';

// auth0
import { useAuth0 } from 'react-auth0-spa';

// constants
import { userPermissionAccessor, HasPermissions } from 'constants/permissions';
import AppRoutes from 'constants/routes';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// mdb
import {
  Navbar, NavbarBrand, NavbarNav, NavItem,
} from 'mdbreact';
import Dropdown from 'react-bootstrap/Dropdown';

const AUTH0_CALLBACK_URL = process.env.REACT_APP_AUTH0_CALLBACK_URI;

export default ({ scenarioId, location, scenarios = [] }) => {
  const {
    isAuthenticated, loginWithRedirect, logout, loading, user,
  } = useAuth0();

  // eslint-disable-next-line no-underscore-dangle
  function _logout() {
    logout({ returnTo: AUTH0_CALLBACK_URL });
  }

  function login() {
    loginWithRedirect({});
  }

  const [buttons, setButtons] = useState([
    <Dropdown.Item onClick={login} key="/login">
      Login
    </Dropdown.Item>,
  ]);

  function getNavHeaderTitle() {
    const pathnameIncludes = snippet => location.pathname.includes(snippet);

    const sId = location.pathname.replace('/scenarios/view/', '');
    const scenario = scenarios.find(s => s.id === sId);

    switch (true) {
    case pathnameIncludes('/defaults'):
    case pathnameIncludes('/users'):
    case pathnameIncludes('/scenarios/edit'):
      return null;

    case pathnameIncludes('/scenarios/view'):
      if (scenario) {
        return (
          <>
            View & Refine&nbsp;
            <small>
              ({ scenario.name })
            </small>
          </>
        );
      }

      return 'View & Refine';

    case pathnameIncludes('/scenarios'):
      return 'Scenario Manager';

    // case pathnameIncludes('/operational'):
    //   return 'Operational View (Coming soon)';
    default:
      return 'Investing in catchments to protect our drinking water';
    }
  }

  const headerTitle = getNavHeaderTitle();
  let navClass = 'navbar navbar-expand-lg navbar-dark';
  if (headerTitle === null) {
    navClass += 'd-none';
  }

  useEffect(() => {
    if (loading) {
      return;
    }

    const btns = [];
    if (!isAuthenticated) {
      btns.push(
        <Dropdown.Item onClick={login} key="/login">
          Login
        </Dropdown.Item>,
      );
      setButtons(btns);
      return;
    }

    // user's permissions
    const perms = userPermissionAccessor(user);
    const routes = [
      AppRoutes.Featured,
      AppRoutes.ScenarioManager,
      AppRoutes.ScenarioView,
      AppRoutes.UserManager,
    ];

    routes.forEach((route) => {
      let { name } = route;
      if (name === 'View & Refine' && location.pathname !== '/') {
        return;
      }

      if (HasPermissions(perms, route.permissions)) {
        let { path } = route;
        if (route.name === 'View & Refine') {
          name = 'Jump to View & Refine';
          path = path.replace(':id', scenarioId);
        }

        btns.push((
          <Dropdown.Item href={path} key={path}>
            {name}
          </Dropdown.Item>
        ));
      }
    });

    btns.push(
      <Dropdown.Divider key="-" />,
      <Dropdown.Item onClick={_logout} key="/logout">
        Logout
      </Dropdown.Item>,
    );
    setButtons(btns);
  }, [loading, user, isAuthenticated, scenarioId, location]);

  // check the route, we only need to show the headerbar on certain routes
  if (getNavHeaderTitle() === null) {
    return null;
  }

  const navButton = React.forwardRef(({ onClick }, ref) => (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
    <span ref={ref} className="fa-layers fa-2x cursor-pointer" onClick={onClick}>
      <FontAwesomeIcon icon={['fal', 'square']} color="white" />
      <FontAwesomeIcon icon={['fal', 'bars']} color="white" transform="shrink-6" />
    </span>
  ));

  return (
    <Navbar className={navClass}>
      <NavbarBrand>
        <span className="navbar-brand">
          { headerTitle }
        </span>
      </NavbarBrand>
      <NavbarNav right>
        <NavItem>
          {loading ? <FontAwesomeIcon icon={['fal', 'spinner']} color="white" spin /> : (
            <Dropdown>
              <Dropdown.Toggle as={navButton} />
              <Dropdown.Menu alignRight>
                { buttons }
              </Dropdown.Menu>
            </Dropdown>
          )}
        </NavItem>
      </NavbarNav>
    </Navbar>
  );
};
