// import { configureStore } from '@reduxjs/toolkit';

import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import { useMemo } from 'react';

// actions/reducers
import scenarios from 'redux/scenarios';
import featuredId from 'redux/featuredid';
import ipCheck from 'redux/ipcheck';
import results from 'redux/results';
import defaults from 'redux/defaults';
import users from 'redux/users';
import attempts from 'redux/attempts';

function useActions(actions, deps) {
  const dispatch = useDispatch();
  return useMemo(() => {
    if (Array.isArray(actions)) {
      return actions.map(a => bindActionCreators(a, dispatch));
    }
    return bindActionCreators(actions, dispatch);
  // eslint-disable-next-line
  }, deps ? [dispatch, ...deps] : [dispatch]);
}

// export const store = configureStore({
//   reducer: {
//     scenarios: scenarios.reducer,
//     featuredId: featuredId.reducer,
//     ipCheck: ipCheck.reducer,
//     results: results.reducer,
//     defaults: defaults.reducer,
//     users: users.reducer,
//   },
//   middleware: [],
// });
export {
  useActions,
};
export const actions = {
  ...scenarios.actions,
  ...featuredId.actions,
  ...ipCheck.actions,
  ...results.actions,
  ...defaults.actions,
  ...users.actions,
  ...attempts.actions,
};
