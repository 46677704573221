import { createSlice } from '@reduxjs/toolkit';
import initialState from 'state';

const { actions, reducer } = createSlice({
  name: 'ipcheck',
  slice: 'ipCheck',
  initialState: initialState.ipCheck,
  reducers: {
    isAuthorised: (state, action) => action.payload,
  },
});

export default {
  reducer,
  actions: { ...actions },
};
