import React, { useEffect, useState } from 'react';
import sock, { listenToWsEvent, connect as connectToWs } from 'websocket';

// router
import {
  BrowserRouter as Router, Route, Switch,
} from 'react-router-dom';

// redux
import { useSelector } from 'react-redux';
import { useActions, actions } from 'store';

// auth0
import { useAuth0 } from 'react-auth0-spa';

// constants
import { userPermissionAccessor, HasPermissions, userRoleAccessor } from 'constants/permissions';
import AppRoutes from 'constants/routes';
import { wsEventsToListenTo } from 'constants/defaults';
import ApiRoutes from 'constants/api/routes';
import { f as fetch } from 'constants/api/fetch';

// components
import Nav from 'Components/Nav';
import Loader from 'Components/Loader';
import Page404 from 'Scenes/ServicePage';

import './App.scss';

const {
  isAuthorised,
  changeFeaturedId,
  updateDefaults,
  handleWsUpdate,
  addAttempt,
  updateScenario,
} = actions;

export default () => {
  const featuredId = useSelector(state => state.featuredId);
  const scenarios = useSelector(state => state.scenarios) ?? [];

  const [checkingIP, setCheckingIP] = useState(true);
  const [isIPAuthorised, setIPAuthorised] = useState(false);
  const [appRoutes, setAppRoutes] = useState([
    <Route component={Loader} key="-1" />,
  ]);

  const acts = useActions({
    isAuthorised,
    changeFeaturedId,
    updateDefaults,
    handleWsUpdate,
    addAttempt,
    updateScenario,
  });

  async function checkIP() {
    const uri = ApiRoutes.checkIP();
    const resp = await fetch(uri);

    if (resp.status === 200) {
      acts.isAuthorised(true);
      setIPAuthorised(true);
      setCheckingIP(false);
      return;
    }

    acts.isAuthorised(false);
    setIPAuthorised(false);
    setCheckingIP(false);
  }

  function wsNewUpdateRemoveScenarios(data) {
    let json = null;
    try {
      json = JSON.parse(data);
    } catch (e) {
      return;
    }
    acts.handleWsUpdate(json);

    if (!(json instanceof Array)) {
      if (json.featured) {
        acts.changeFeaturedId(json.id);
      }
    }
  }

  function wsUpdateDefaults(data) {
    let json = null;
    try {
      json = JSON.parse(data);
    } catch (e) {
      console.log('e', e);
      return;
    }

    acts.updateDefaults(json);
  }

  function wsAddAttempts(data) {
    let json = null;
    try {
      json = JSON.parse(data);
    } catch (e) {
      console.log('e', e);
      return;
    }

    acts.addAttempt(json);
  }

  function wsUpdateProgress(data) {
    let json = null;
    try {
      json = JSON.parse(data);
    } catch (e) {
      console.log('e', e);
      return;
    }

    acts.updateScenario(json);
  }

  const {
    isAuthenticated, loginWithRedirect, loading, user, idToken,
  } = useAuth0();

  useEffect(() => {
    checkIP();
  }, []);

  useEffect(() => {
    if (sock.socket === null) {
      return;
    }

    listenToWsEvent(wsEventsToListenTo.NEW_UPDATE_REMOVE_SCENARIOS, wsNewUpdateRemoveScenarios);
    listenToWsEvent(wsEventsToListenTo.UPDATE_DEFAULTS, wsUpdateDefaults);
    listenToWsEvent(wsEventsToListenTo.NEW_ATTEMPTS, wsAddAttempts);
    listenToWsEvent(wsEventsToListenTo.RUN_PROGRESS_SCENARIO, wsUpdateProgress);
  }, [sock.socket]);

  useEffect(() => {
    if (!loading && !isAuthenticated && !checkingIP) {
      // check if the ip is at least verified
      if (!isIPAuthorised) {
        // go login
        loginWithRedirect({});
      }
    }
  }, [loading, isAuthenticated, isIPAuthorised, checkingIP]);

  useEffect(() => {
    if (loading || isAuthenticated) {
      return;
    }

    const config = AppRoutes.Featured;
    const route = <Route exact path={config.path} key={config.path} component={config.route} />;
    setAppRoutes([route]);
  }, [loading, isAuthenticated, isIPAuthorised]);

  useEffect(() => {
    // get the user's profile and their metadata for the app's permissions
    if (loading) {
      return;
    }

    if (!isAuthenticated) {
      return;
    }

    // set the id token in the localstorage
    localStorage.setItem('id_token', idToken);
    const role = userRoleAccessor(user);
    connectToWs(role);

    // get the user's permissions
    const perms = userPermissionAccessor(user);

    // add routes to the state for the app to use if the permissions match
    const availableRoutes = Object.keys(AppRoutes).map((key) => {
      const route = AppRoutes[key];

      // check if the user's permissions allow for this route to be added
      if (HasPermissions(perms, route.permissions)) {
        return <Route exact path={route.path} key={route.path} component={route.route} />;
      }

      return null;
    });

    // add the featured route if the ip is whitelisted and they're not logged in
    setAppRoutes(availableRoutes);
  }, [loading, user, isAuthenticated]);

  return (
    <Router>
      <div className="app">
        <Route component={props => <Nav scenarioId={featuredId} {...props} scenarios={scenarios} />} />
        <Switch>
          { appRoutes }
          <Route component={Page404} />
        </Switch>
      </div>
    </Router>
  );
};
