import { createSlice } from '@reduxjs/toolkit';
import initialState from 'state';
import deepClone from 'lodash.clonedeep';
import { defaultResultsInnerState } from 'constants/defaults';

const { actions, reducer } = createSlice({
  name: 'results',
  slice: 'results',
  initialState: initialState.results,
  reducers: {
    addCatchmentPUSpatial: (state, action) => {
      const obj = state;
      if (obj[action.payload.scenarioId] === undefined) {
        // cloning the variable since it's an object
        // and we don't want to be changing the original variable either
        obj[action.payload.scenarioId] = deepClone(defaultResultsInnerState);
      }

      obj[action.payload.scenarioId].spatialPU[action.payload.catchmentId] = action.payload.spatial;
    },
    addCatchmentPUResults: (state, action) => {
      const obj = state;
      if (obj[action.payload.scenarioId] === undefined) {
        obj[action.payload.scenarioId] = deepClone(defaultResultsInnerState);
      }

      obj[action.payload.scenarioId].resultsPU[action.payload.catchmentId] = action.payload.results;
    },
    addScenarioSpatial: (state, action) => {
      const obj = state;
      if (obj[action.payload.scenarioId] === undefined) {
        obj[action.payload.scenarioId] = deepClone(defaultResultsInnerState);
      }

      obj[action.payload.scenarioId].spatial = action.payload.spatial;
    },
    addScenarioResults: (state, action) => {
      const obj = state;
      if (obj[action.payload.scenarioId] === undefined) {
        obj[action.payload.scenarioId] = deepClone(defaultResultsInnerState);
      }

      obj[action.payload.scenarioId].results = action.payload.results;
    },
    addScenarioResultsCatchment: (state, action) => {
      const obj = state;
      if (obj[action.payload.scenarioId] === undefined) {
        obj[action.payload.scenarioId] = deepClone(defaultResultsInnerState);
      }

      obj[action.payload.scenarioId].catchments = action.payload.catchments;
    },
    addScenarioRawThreat: (state, action) => {
      const obj = state;
      if (obj[action.payload.scenarioId] === undefined) {
        obj[action.payload.scenarioId] = deepClone(defaultResultsInnerState);
      }

      obj[action.payload.scenarioId].rawThreat = action.payload.rawThreat;
    },
    addScenarioAttenuatedThreat: (state, action) => {
      const obj = state;
      if (obj[action.payload.scenarioId] === undefined) {
        obj[action.payload.scenarioId] = deepClone(defaultResultsInnerState);
      }

      obj[action.payload.scenarioId].attenuatedThreat = action.payload.attenuatedThreat;
    },
    addAttemptCatchmentPUSpatial: (state, action) => {
      if (state[action.payload.attemptId] === undefined) {
        const obj = {
          [action.payload.attemptId]: deepClone(defaultResultsInnerState),
        };
        Object.assign(state, obj);
      }

      const obj = { [action.payload.catchmentId]: action.payload.spatial };
      Object.assign(state[action.payload.attemptId].spatialPU, obj);
    },
    addAttemptCatchmentPUResults: (state, action) => {
      if (state[action.payload.attemptId] === undefined) {
        const obj = {
          [action.payload.attemptId]: deepClone(defaultResultsInnerState),
        };
        Object.assign(state, obj);
      }

      const obj = { [action.payload.catchmentId]: action.payload.results };
      Object.assign(state[action.payload.attemptId].resultsPU, obj);
    },
    addAttemptSpatial: (state, action) => {
      if (state[action.payload.attemptId] === undefined) {
        const obj = {
          [action.payload.attemptId]: deepClone(defaultResultsInnerState),
        };
        Object.assign(state, obj);
      }

      Object.assign(state[action.payload.attemptId].spatial, action.payload.spatial);
    },
    addAttemptResults: (state, action) => {
      if (state[action.payload.attemptId] === undefined) {
        const obj = {
          [action.payload.attemptId]: deepClone(defaultResultsInnerState),
        };
        Object.assign(state, obj);
      }

      Object.assign(state[action.payload.attemptId].results, action.payload.results);
    },
    addAttemptResultsCatchment: (state, action) => {
      if (state[action.payload.attemptId] === undefined) {
        const obj = {
          [action.payload.attemptId]: deepClone(defaultResultsInnerState),
        };
        Object.assign(state, obj);
      }

      Object.assign(state[action.payload.attemptId].catchments, action.payload.catchments);
    },
    addAttemptRawThreat: (state, action) => {
      if (state[action.payload.attemptId] === undefined) {
        const obj = {
          [action.payload.attemptId]: deepClone(defaultResultsInnerState),
        };
        Object.assign(state, obj);
      }

      Object.assign(state[action.payload.attemptId].rawThreat, action.payload.rawThreat);
    },
  },
});

export default {
  reducer,
  actions: { ...actions },
};
