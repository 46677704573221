import Loadable from 'react-loadable';
import Loader from 'Components/Loader';

import { permissions } from 'constants/permissions';

const AppRoutes = {
  Featured: {
    route: Loadable({
      loader: () => import('Scenes/Featured/hook'),
      loading: Loader,
    }),
    path: '/',
    name: 'Featured',
    permissions: permissions.FeaturedRead,
  },
  ScenarioManager: {
    route: Loadable({
      loader: () => import('Scenes/ScenarioManager/hook'),
      loading: Loader,
    }),
    path: '/scenarios',
    name: 'Scenario manager',
    permissions: permissions.ScenarioManagerRead,
  },
  ScenarioEdit: {
    route: Loadable({
      loader: () => import('Scenes/ScenarioEdit/hook'),
      loading: Loader,
    }),
    path: '/scenarios/edit/:id',
    name: 'Scenario edit',
    permissions: permissions.ScenarioEditRead,
  },
  ScenarioView: {
    route: Loadable({
      loader: () => import('Scenes/ScenarioView/hook'),
      loading: Loader,
    }),
    path: '/scenarios/view/:id',
    name: 'View & Refine',
    permissions: permissions.ScenarioViewRead,
  },
  ScenarioViewAttempt: {
    route: Loadable({
      loader: () => import('Scenes/ScenarioView/hook'),
      loading: Loader,
    }),
    path: '/scenarios/view/:id/attempt/:aid',
    name: 'View & Refine',
    permissions: permissions.ScenarioViewWrite,
  },
  Defaults: {
    route: Loadable({
      loader: () => import('Scenes/Defaults/hook'),
      loading: Loader,
    }),
    path: '/defaults',
    name: 'Defaults',
    permissions: permissions.DefaultsRead,
  },
  UserManager: {
    route: Loadable({
      loader: () => import('Scenes/UserManager/hook'),
      loading: Loader,
    }),
    path: '/users',
    name: 'User manager',
    permissions: permissions.UserManagementRead,
  },
};
export default AppRoutes;
