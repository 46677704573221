import { createSlice } from '@reduxjs/toolkit';
import initialState from 'state';

const { actions, reducer } = createSlice({
  name: 'users',
  slice: 'users',
  initialState: initialState.users,
  reducers: {
    addUsers: (state, action) => {
      action.payload.forEach((user) => {
        const index = state.findIndex(u => u.id === user.user_id);
        if (index > -1) {
          state.splice(index, 1);
        }
      });

      state.push(...action.payload);
    },
    setUsers: (state, action) => action.payload,
    updateUser: (state, action) => {
      const user = state.find(u => u.user_id === action.payload.user_id);

      Object.assign(user, action.payload);
    },
  },
});

export default {
  reducer,
  actions: { ...actions },
};
