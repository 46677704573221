import { createSlice } from '@reduxjs/toolkit';
import initialState from 'state';

const { actions, reducer } = createSlice({
  name: 'attempts',
  slice: 'attempts',
  initialState: initialState.attempts,
  reducers: {
    addAttempt: (state, action) => {
      const index = state.findIndex(a => a.id === action.payload.id);
      if (index > -1) {
        state.splice(index, 1, action.payload);
        return;
      }

      state.push(action.payload);
    },
  },
});

export default {
  reducer,
  actions: { ...actions },
};
