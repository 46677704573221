import React from 'react';
import PropTypes from 'prop-types';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as Animiation } from './truii-animated.svg';

const Loader = ({ message, fullpage = true, stop = false }) => {
  if (fullpage) {
    return (
      <div
        style={{
          height: '94vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div className="row justify-content-center text-center">
          <div className="col-12 mb-4">
            { !stop
              ? <Animiation width="200px" />
              : <FontAwesomeIcon icon={faExclamationTriangle} size="4x" /> }
          </div>
          <div className="col-12">
            <h5>{ message }</h5>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="row justify-content-center text-center">
      <div className="col-12 mb-4">
        { !stop
          ? <Animiation width="130px" />
          : <FontAwesomeIcon icon={faExclamationTriangle} size="4x" /> }
      </div>
      <div className="col-12">
        <h5>{ message }</h5>
      </div>
    </div>
  );
};
export default Loader;
Loader.propTypes = {
  message: PropTypes.string,
  fullpage: PropTypes.bool,
  stop: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

Loader.defaultProps = {
  message: '',
  fullpage: true,
  stop: false,
};
