import { createSlice } from '@reduxjs/toolkit';
import initialState from 'state';
// import isEqual from 'lodash.isequal';

const { actions, reducer } = createSlice({
  name: 'scenarios',
  slice: 'scenarios',
  initialState: initialState.scenarios,
  reducers: {
    addScenarios: (state, action) => {
      // remove any duplicate scenarios
      action.payload.forEach((scenario) => {
        const index = state.findIndex(s => s.id === scenario.id);
        if (index > -1) {
          const original = state[index];
          // check if the catchments in the payload are a string array
          // we want to discard any string array's since they're unnecessary (nothing was changed in them)
          // so we'll keep our original catchments if that's the case

          // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/instanceof see example, "Demonstrating that String and Date are of type Object and exceptional cases"
          // typeof does work
          if (scenario.catchments.length > 0 && typeof (scenario.catchments[0]) === 'string') {
            scenario.catchments = original.catchments;
          }

          state.splice(index, 1);
        }
      });

      state.push(...action.payload);
    },
    addScenario: (state, action) => {
      // check if the scenario already exists in the state
      const index = state.findIndex(s => s.id === action.payload.id);
      if (index > -1) {
        // already in the state, we need to splice our new payload in it's place
        state.splice(index, 1, action.payload);
        return;
      }

      state.push(action.payload);
    },
    removeScenario: (state, action) => {
      const index = state.findIndex(s => s.id === action.payload);
      state.splice(index, 1);
    },
    updateScenario: (state, action) => {
      // merge the payload into the existing state
      const scenario = state.find(s => s.id === action.payload.id);
      Object.assign(scenario, action.payload);
    },
    updateCatchment: (state, action) => {
      // merge the payload into the existing state
      const scenario = state.find(s => s.id === action.payload.scenarioId);
      let catchment = {};
      if (scenario.catchments.length === 0) {
        // don't do anything, there's no catchments for this scenario
        return;
      }

      if ((typeof (scenario.catchments[0])).toLowerCase() === 'string') {
        catchment = scenario.catchments.find(c => c === action.payload.id);
      } else {
        catchment = scenario.catchments.find(c => c.id === action.payload.id);
      }

      // no catchment was found with the payload's id
      if (!catchment) {
        return;
      }

      // remove the scenario id from the payload
      delete action.payload.scenarioId;

      Object.assign(catchment, action.payload);
    },
    handleWsUpdate: (state, action) => {
      if (action.payload instanceof Array) {
        // add the scenarios into the state
        action.payload.forEach((scenario) => {
          const index = state.findIndex(s => s.id === scenario.id);
          if (index > -1) {
            const original = state[index];
            if (scenario.catchments.length > 0 && typeof (scenario.catchments[0]) === 'string') {
              Object.assign(scenario.catchments, original.catchments);
            }

            state.splice(index, 1);
          }
        });
        state.push(...action.payload);

        // remove scenarios from the state if they're not in the payload
        if (action.payload.length < state.length) {
          const ids = action.payload.map(s => s.id);
          state.forEach((scenario, index) => {
            if (ids.indexOf(scenario.id) === -1) {
              state.splice(index, 1);
            }
          });
        }

        return;
      }

      const index = state.findIndex(s => s.id === action.payload.id);
      if (index > -1) {
        const scenario = state[index];
        if (action.payload.catchments.length > 0 && typeof (action.payload.catchments[0]) === 'string') {
          Object.assign(action.payload.catchments, scenario.catchments);
        }

        Object.assign(state[index], action.payload);
        return;
      }

      state.push(action.payload);
    },
  },
});

export default {
  reducer,
  actions: { ...actions },
};
