import * as socketIo from 'socket.io-client';

const host = process.env.REACT_APP_API_BACKEND_WEBSOCKETS;
const sock = {
  socket: null,
};
export default sock;

export function listenToWsEvent(eventName, callback = () => {}) {
  sock.socket.off(eventName, callback);
  sock.socket.on(eventName, callback);
  
  console.log(`listening to ws ${eventName}`);
}

export function connect(role) {
  const socket = socketIo(host, {
    origin: ':',
    query: {
      token: localStorage.getItem('id_token'),
      role,
    },
    reconnectionDelay: 5000,
  });
  sock.socket = socket;

  socket.on('connect', () => console.log('Socket opened!'));
  socket.on('reconnect', () => console.log('Socket reopened!'));
  socket.on('disconnect', () => console.log('Socket unexpectantly closed...'));
  socket.on('error', (err) => {
    console.error(err);
    socket.io.opts.query.token = localStorage.getItem('id_token');
  });
}
