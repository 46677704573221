import { createSlice } from '@reduxjs/toolkit';
import initialState from 'state';

const { actions, reducer } = createSlice({
  name: 'defaults',
  slice: 'defaults',
  initialState: initialState.defaults,
  reducers: {
    addDefaults: (state, action) => action.payload,
    addCatchment: (state, action) => {
      state.catchments.push(action.payload);
    },
    removeCatchment: (state, action) => {
      Object.assign(state.catchments, state.catchments.filter(c => c.id !== action.payload));
    },
    updateDefaults: (state, action) => {
      Object.assign(state, action.payload);
    },
  },
});

export default {
  reducer,
  actions: { ...actions },
};
