import { createSlice } from '@reduxjs/toolkit';
import initialState from 'state';

const { actions, reducer } = createSlice({
  name: 'featuredid',
  slice: 'featuredId',
  initialState: initialState.featuredId,
  reducers: {
    changeFeaturedId: (state, action) => action.payload,
  },
});

export default {
  reducer,
  actions: { ...actions },
};
