// export const attempts = [];
// export const defaults = {};
// export const users = [];
// export const scenarios = [];
// export const catchments = [];
// export const results = {};
// export const metadata = JSON.parse(decode('eyJjaWRzcyI6IHsicm9sZSI6ICJ1c2VyIn19'));
// export const featured = false;
// export const fetching = {};
// export const ipcheck = null;
// export const wslistening = {};
// export const files = {};
// export const interventionChanges = {};

export default {
  attempts: [],
  defaults: {},
  scenarios: [],
  results: {},
  featuredId: null,
  ipCheck: null,
  interventionChanges: {},
  users: [],
};
