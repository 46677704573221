export default null;

export const defaultUserMetadata = 'eyJjaWRzcyI6IHsicm9sZSI6ICJ1c2VyIn19';

export const CurrencyFormat = Intl.NumberFormat('en-AU', {
  style: 'currency',
  currency: 'AUD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const AppRoutes = {
  Scenarios: {
    Manager: () => `/scenarios`,
    Edit: scenarioId => `/scenarios/edit/${scenarioId}`,
    ViewRefine: scenarioId => `/scenarios/view/${scenarioId}`,
  },
}

export const defaultScenarioStatus = '';

export const defaultResultsInnerState = {
  spatial: {},
  results: {},
  catchments: [],
  resultsPU: {},
  spatialPU: {},
  rawThreat: {},
  attenuatedThreat: {},
};

export const MapboxGLToken = process.env.REACT_APP_MAPBOXGL_TOKEN;

export const TILE_URL = process.env.REACT_APP_BACKEND_TILE_API;

export const TILE_JSON_URL = process.env.REACT_APP_BACKEND_TILE_JSON_API;

export const BlobStorageAccount = process.env.REACT_APP_AZURE_BLOB_ACCOUNT || 'devtruiistorage';

export const AzureBlobConnectionString = process.env.REACT_APP_AZURE_BLOB_CONNECTION_URI;

export const AzureBlobContainerName = process.env.REACT_APP_AZURE_BLOB_CONTAINER;

export const ViewAndRefineAnnotationReasons = [
  'Hazard does not exist or is a different size',
  'Hazard has already been treated',
  'Interventions are not appropriate',
  'Intervention cost is different',
];

export const defaultRiskWeights = {
  tss: 25,
  protozoa: 25,
  bacteria: 25,
  virus: 25,
};

export const requiredFilesToRunScenario = [
  'spatial',
  'pu',
  'pu_properties',
  'raw_threat',
  'risk',
  'intervention_limits',
  'storm_water',
  'agriculture',
  'industry',
  'stp',
  'onsite',
  'intervention_group_limits',
];

export const defaultMaxHazardWeight = 100;
export const defaultMinHazardWeight = 0;

export const knownHazards = [
  'TSS',
  'Bacteria',
  'Virus',
  'Protozoa',
];

export const fileInputTypeWhitelist = {
  CSV: '.csv',
  GEOJSON: '.geojson',
};

export const scenarioRunModes = {
  threat: 0,
  risk: 1,
};

export const wsEventsToListenTo = {
  NEW_UPDATE_REMOVE_SCENARIOS: 'scenarios',
  RUN_PROGRESS_SCENARIO: 'progress',
  NEW_ATTEMPTS: 'attempts',
  UPDATE_DEFAULTS: 'defaults',
  NEW_UPDATE_REMOVE_CATCHMENTS: 'catchments',
  NEW_UPDATE_REMOVE_USERS: 'users',
};
